<template>
    <AppHeader>
        <template v-slot:module-navbar>
            <router-link to="/landing" class="nav-link" role="button">
                <span class="nav-link-inner--text">Dashboard</span>
            </router-link>
            <base-dropdown v-if="isOrgSelected" class="nav-item" id="accordionExample" menu-classes="dropdown-menu-xl"
                :accordian-menu-click="accordianCheck" @change="accordianClicked">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button" @click="accordianClicked(false)">
                        <span class="nav-link-inner--text">Products</span>
                    </a>
                </template>
                <div class="dropdown-menu-inner product-menu accordion" role="tablist">
                    <div class="mb-3">
                        <div class="row" @click="accordianClicked(false)">
                            <div class="col">
                                <router-link v-if="authAssert.edit"
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/products/create' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">New Product</h6>
                                        <p class="description d-none d-md-inline-block mb-0">Create and configure a
                                            new product.</p>
                                    </div>
                                </router-link>
                                <router-link
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/products/view' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">View Product Portfolio</h6>
                                        <p class="description d-none d-md-inline-block mb-0">View all of the
                                            products in your portfolio.</p>
                                    </div>
                                </router-link>
                                <router-link v-if="showPortfolioValidaton"
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/portfolioValidation/validationOptions' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">Product Validation</h6>
                                        <p class="description d-none d-md-inline-block mb-0">Configure product
                                            validation.</p>
                                    </div>
                                </router-link>
                                <router-link v-if="showBulkRateEdit"
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/products/rates' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">Bulk Rate Edit</h6>
                                        <p class="description d-none d-md-inline-block mb-0">View and edit lending
                                            and deposit rates for all products.</p>
                                    </div>
                                </router-link>
                                <router-link
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/fees/view' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">Library of Standard Fees</h6>
                                        <p class="description d-none d-md-inline-block mb-0">Create and manage
                                            standard fees used in multiple products.</p>
                                    </div>
                                </router-link>
                            </div>

                        </div>
                    </div>


                </div>
            </base-dropdown>
            <base-dropdown v-if="isOrgSelected" class="nav-item" id="accordionExample" menu-classes="dropdown-menu-xl"
                :accordian-menu-click="accordianCheck" @change="accordianClicked">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button" @click="accordianClicked(false)">
                        <span class="nav-link-inner--text">TMD's</span>
                    </a>
                </template>
                <div class="dropdown-menu-inner product-menu accordion" role="tablist">
                    <div class="mb-3">
                        <div class="row" @click="accordianClicked(false)">
                            <div class="col">
                                <router-link v-if="authAssert.edit && showTmd"
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/tmds/create' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">New Target Market</h6>
                                        <p class="description d-none d-md-inline-block mb-0">Create and configure a
                                            new target market.</p>
                                    </div>
                                </router-link>
                                <router-link v-if="showTmd"
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/tmds/view' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">View Target Markets</h6>
                                        <p class="description d-none d-md-inline-block mb-0">View all of the target
                                            market determinations in your portfolio.</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </base-dropdown>
            <base-dropdown v-if="isOrgSelected" class="nav-item" id="accordionExample" menu-classes="dropdown-menu-xl"
                :accordian-menu-click="accordianCheck" @change="accordianClicked">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button" @click="accordianClicked(false)">
                        <span class="nav-link-inner--text">Workflows</span>
                    </a>
                </template>
                <div class="dropdown-menu-inner product-menu accordion" role="tablist">                    
                    <div class="mb-3">
                        <div class="row" @click="accordianClicked(false)">
                            <div class="col">
                                <router-link
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/organisationWorkflows' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">View Workflows</h6>
                                        <p class="description d-none d-md-inline-block mb-0">View all workflows
                                            within your organisation.</p>
                                    </div>
                                </router-link>
                                <router-link
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/events/future' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">Future Events</h6>
                                        <p class="description d-none d-md-inline-block mb-0">View all the events
                                            scheduled .</p>
                                    </div>
                                </router-link>
                                <router-link :to="{ path: '/org/' + store.state.organisation.organisationId + '/reviews' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">My Reviews</h6>
                                        <p class="description d-none d-md-inline-block mb-0">In progress changes,
                                            reviews and approvals.</p>
                                    </div>
                                </router-link>
                                <router-link
                                    :to="{ path: '/org/' + store.state.organisation.organisationId + '/groupWorkflows/create/product' }"
                                    class="media d-flex align-items-center">
                                    <div class="media-body">
                                        <h6 class="heading text-primary mb-md-1">Create Group Workflows</h6>
                                        <p class="description d-none d-md-inline-block mb-0">Create group workflows
                                            in your portfolio.</p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </base-dropdown>

            <base-dropdown v-if="false" class="nav-item" menu-classes="dropdown-menu-xl">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text">Usage</span>
                    </a>
                </template>

                <div class="dropdown-menu-inner">
                    <router-link to="/usage/external" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">3rd Party Usage</h6>
                            <p class="description d-none d-md-inline-block mb-0">Statistics on 3rd party access of your
                                product information.</p>
                        </div>
                    </router-link>
                    <router-link to="/usage/internal" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">My Channel Usage</h6>
                            <p class="description d-none d-md-inline-block mb-0">Statistics on access of your product
                                information for your own channels.</p>
                        </div>
                    </router-link>
                </div>
            </base-dropdown>

            <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl dropdown-menu-right">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text">Insight</span>
                    </a>
                </template>

                <div class="dropdown-menu-inner">
                    <router-link to="/insight/portfolio" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Market Portfolios</h6>
                            <p class="description d-none d-md-inline-block mb-0">Review market product portfolios.</p>
                        </div>
                    </router-link>
                    <router-link to="/insight/compare" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Market Comparison</h6>
                            <p class="description d-none d-md-inline-block mb-0">Compare your portfolio to the market.
                            </p>
                        </div>
                    </router-link>
                    <router-link v-if="false" to="/insight/trend" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Trend Analysis</h6>
                            <p class="description d-none d-md-inline-block mb-0">Assess the trends of market changes
                                over time.</p>
                        </div>
                    </router-link>
                    <router-link to="/insight/changes" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Market Changes</h6>
                            <p class="description d-none d-md-inline-block mb-0">Review product changes across the
                                market over time.</p>
                        </div>
                    </router-link>
                    <router-link to="/insight/report" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Market Reports</h6>
                            <p class="description d-none d-md-inline-block mb-0">Generate and export standardised
                                reports</p>
                        </div>
                    </router-link>
                    <router-link to="/insight/notifications" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Market Notifications</h6>
                            <p class="description d-none d-md-inline-block mb-0">Market events of specific interest.</p>
                        </div>
                    </router-link>
                </div>
            </base-dropdown>

            <base-dropdown v-if="showSecure && isOrgSelected" class="nav-item"
                menu-classes="dropdown-menu-xl dropdown-menu-right">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text">Reporting</span>
                    </a>
                </template>

                <div class="dropdown-menu-inner">
                    <router-link v-if="isOrgSelected && authAssert.admin"
                        :to="{ path: `/settings/${store.state.organisation.organisationId}/activity` }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">User Activity</h6>
                            <p class="description d-none d-md-inline-block mb-0">Monitor activity for all users in the
                                organisation.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected"
                        :to="{ path: `/settings/api/${store.state.organisation.organisationId}/metrics/invocation` }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Usage Analytics</h6>
                            <p class="description d-none d-md-inline-block mb-0">Monitor API usage.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected"
                        :to="{ path: `/org/${store.state.organisation.organisationId}/report` }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Organisation Reports</h6>
                            <p class="description d-none d-md-inline-block mb-0">Generate and export standardised
                                reports.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected"
                        :to="{ path: `/org/${store.state.organisation.organisationId}/products/view/rates` }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">View Product Rates & Fees</h6>
                            <p class="description d-none d-md-inline-block mb-0">View rates and fees of all the
                                products.</p>
                        </div>
                    </router-link>
                </div>
            </base-dropdown>

            <base-dropdown v-if="showSecure && isOrgSelected" class="nav-item"
                menu-classes="dropdown-menu-xl dropdown-menu-right">
                <template #title>
                    <a class="nav-link" data-toggle="dropdown" role="button">
                        <span class="nav-link-inner--text">Settings</span>
                    </a>
                </template>

                <div class="dropdown-menu-inner">
                    <router-link v-if="isOrgSelected"
                        :to="{ path: '/settings/api/external/' +store.state.organisation.organisationId }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">3rd Party API Configuration</h6>
                            <p class="description d-none d-md-inline-block mb-0">Configure access to product data via
                                CDR compliant APIs.</p>
                        </div>
                    </router-link>
                    <router-link v-if="false" to="/settings/api/internal" class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Channel API Configuration</h6>
                            <p class="description d-none d-md-inline-block mb-0">Configure access to product data via
                                your own channels.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected && authAssert.admin"
                        :to="{ path: '/org/' +store.state.organisation.organisationId + '/settings/general/security' }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Customise ProductCloud</h6>
                            <p class="description d-none d-md-inline-block mb-0">General ProductCloud configurations and
                                settings.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected && authAssert.admin"
                        :to="{ path: '/org/' +store.state.organisation.organisationId + '/settings/products/schema' }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Customise Product Schema</h6>
                            <p class="description d-none d-md-inline-block mb-0">Customise product schema for CDR and
                                internal products.</p>
                        </div>
                    </router-link>
                    <router-link v-if="isOrgSelected && showTmd && authAssert.admin"
                        :to="{ path: '/org/' +store.state.organisation.organisationId + '/settings/tmds/schema' }"
                        class="media d-flex align-items-center">
                        <div class="media-body">
                            <h6 class="heading text-primary mb-md-1">Customise Target Market Schema</h6>
                            <p class="description d-none d-md-inline-block mb-0">Customise the data schema for defining
                                target markets.</p>
                        </div>
                    </router-link>
                </div>
            </base-dropdown>
        </template>
    </AppHeader>
</template>

<script lang="ts">
import { AuthAssert } from '@/auth/assertAuth'
import AppHeader from '@/framework/components/layout/AppHeader.vue'
import BaseDropdown from '@/modules/common/components/argon/BaseDropdown.vue'
import { IFetchable, IUserProfile } from '@/modules/common/domain/types'
import { IOrganisation } from '@/modules/products/domain/types'
import {IUserLocalStorage} from "@/modules/common/domain/types"
import { saveOrgIdToLocalStorage, loadOrgIdFromLocalStorage } from '@/localStorage'
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";

export default defineComponent({
    name: 'app-header',
    components: {
        AppHeader,
        BaseDropdown
    },
    setup() {

        const authAssert = ref<AuthAssert>(new AuthAssert());
        const accordianCheck = ref<boolean>(false)
        const route = useRoute();
        const router = useRouter();

        const isLoading = computed((): Boolean => {
            if (user.value && user.value.isFetching) return true
            if (organisation.value && organisation.value.isFetching) return true
            return false
        })

        const user = computed((): IUserProfile & IFetchable<IUserProfile> => {
            return store.state.currentUser
        })

        const organisation = computed((): IOrganisation & IFetchable<IOrganisation> => {
            return store.state.organisation
        })

        const showSecure = computed((): Boolean => {
            return store.state.showSecure
        })

        const isOrgSelected = computed((): Boolean => {
            try {
                if (store.state.organisation.organisationId) return true;
            } catch (_) {}
            return false;
        })

        const showTmd = computed((): Boolean => {
            if (organisation.value.features && organisation.value.features.tmd) return true;
            return false;
        })

        const showPortfolioValidaton = computed((): Boolean => {
            const { edit, admin, support, approve} = authAssert.value
            return edit || admin || support || approve
        })
        const showBulkRateEdit = computed((): Boolean => {
            const { edit, admin, support, approve} = authAssert.value
            return edit || admin || support || approve
        })

        async function orgSelected(organisationId: string) {
            try {
                if (route.name !== "Landing") {
                    await router.push({
                        name: 'Landing',
                        params: {
                            orgId: organisationId
                        }
                    });
                }
                await organisation.value.fetch(organisationId);

                const idFromStorage : IUserLocalStorage = loadOrgIdFromLocalStorage()
                if (idFromStorage) {
                    if (idFromStorage.lastOrgId != organisationId) {
                        saveOrgIdToLocalStorage(organisationId, store.state.$auth.user.userId)
                    } else if (idFromStorage.userId != store.state.$auth.user.userId) {
                        saveOrgIdToLocalStorage(organisationId, store.state.$auth.user.userId)
                    }
                }
            } catch (_) {}
        }

        function accordianClicked(state: boolean) {
            accordianCheck.value = state
        }

        return {
            showSecure,
            isOrgSelected,
            accordianClicked,
            authAssert,
            showPortfolioValidaton,
            showBulkRateEdit,
            store,
            accordianCheck,
            showTmd,
            isLoading,
            orgSelected,
            user,
        }
    }
})

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
// @import "~bootstrap/scss/variables";

.profile-dropdown {
    max-height: 85vh;
    min-width: 300px !important;
    border-radius: 0.3rem;
    overflow-y: auto;
}
.profile-icon {
    background: white;
    border-radius: 50%;
    color: white;
    padding: 12px;
    color: white;
    object-fit: center;
    object-position: center;
    background-size: contain;
    font-weight: bold;
}
.header-org-container {
    max-height: 15vh;
    overflow-y: auto;
}
.profile-option-container {
    font-family: inherit;
    h6 {
        text-transform: none;
        margin-bottom: 0;
    }
    .profile-option-icon {
        font-size: $font-size-lg;
    }
}
.version-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.app-version {
    color: $gray-600;
}
.local-yscroll {
    overflow-y: auto;
}
.product-menu {
    max-height: 620px;
    overflow-y: auto;
}
.collapsible {
    width: 100%;
    margin: 0;
    padding: 0;
}
.collapse-button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.collapse-button:focus {
        outline:none
}
    .collapsed > .opened,
    :not(.collapsed) > .closed {
    display: none;
}

.btn-Enter {
    color: $gray-900 !important;
}

</style>
