import { IConsoleMessage, ICustomTag, ISectionTag } from  "@/modules/products/domain/types"
import moment from 'moment';
import store from '@/store';
// import { BreadcrumbPlugin } from 'bootstrap-vue';

export const targetMarketValidator = function(tmdJSON: any): IConsoleMessage[] {
    let errorsArray: IConsoleMessage[] = [];
    checkForBasicFields(tmdJSON, errorsArray);
    if (tmdJSON.hasOwnProperty("accountReviews")) checkForAcountReviewFields(tmdJSON, errorsArray);
    if (tmdJSON.hasOwnProperty("tmdReviews")) checkForTMDReviewFields(tmdJSON, errorsArray)

        let internalCategory = getCategory(tmdJSON.marketCategory);
        let sections;
            if (internalCategory && internalCategory.sections) {
                sections = internalCategory.sections;
            }
            if (sections && sections.length > 0) {
                for (let section of sections) {
                    if (store.state.organisation.settings && store.state.organisation.settings.tmdSchema && store.state.organisation.settings.tmdSchema.sections[section]) {
                        let customSection = store.state.organisation.settings.tmdSchema.sections[section];
                        if (customSection.fields && customSection.fields.tags) checkForCustomTags(tmdJSON[section], customSection.fields.tags, errorsArray, customSection.label, section);
                    }
                }
            }
    return errorsArray;
}

function getCategory(category: string) {
    if (store.state.organisation.settings && store.state.organisation.settings.tmdSchema && store.state.organisation.settings.tmdSchema.categories && store.state.organisation.settings.tmdSchema.sections) {
        if (store.state.organisation.settings.tmdSchema.categories[category]) {
            return store.state.organisation.settings.tmdSchema.categories[category];
        };
    }
}

function checkForBasicFields(tmdJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields: any = [
        {
            key: "marketCategory",
            humanFormat: "Market Category"
        },
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "description",
            humanFormat: "Description"
        }
    ]

    const otherFields: any = [
        {
            key: "characteristics",
            humanFormat: "Characteristics",
            pattern: "stringArray"
        },
        {
            key: "checklist",
            humanFormat: "Checklist",
            pattern: "stringArray"
        },
        {
            key: "distribution",
            humanFormat: "Distribution",
            pattern: "stringArray"
        },
        {
            key: "objectives",
            humanFormat: "Objectives",
            pattern: "stringArray"
        },
        {
            key: "requiredInfo",
            humanFormat: "Required Information",
            pattern: "stringArray"
        },
        {
            key: "riskProfile",
            humanFormat: "Risk Profile"
        },
        {
            key: "experience",
            humanFormat: "Experience"
        }
    ]

    for (const manField of mandatoryFields) {
        validateMandatoryField(tmdJSON, manField, errorsArray, 'TMD Details');
    }

    for (const othField of otherFields) {
        validateOtherField(tmdJSON, othField, errorsArray, 'TMD Details');
    }
}

function checkForAcountReviewFields(tmdJSON: any, errorsArray: IConsoleMessage[]): void {
    const otherFields = [
        {
            key: "periodic",
            humanFormat: "Periodic",
            pattern: "stringArray"
        },
        {
            key: "triggers",
            humanFormat: "Triggers",
            pattern: "stringArray"
        }
    ]

    const jsonKey: string = "accountReviews";

    for (const field of otherFields) {
        validateOtherField(tmdJSON.accountReviews, field, errorsArray, "Account Reviews");
    }
}

function checkForTMDReviewFields(tmdJSON: any, errorsArray: IConsoleMessage[]): void {
    const otherFields = [
        {
            key: "periodic",
            humanFormat: "Periodic",
            pattern: "stringArray"
        },
        {
            key: "triggers",
            humanFormat: "Triggers",
            pattern: "stringArray"
        }
    ]

    const jsonKey: string = "tmdReviews";

    for (const othField of otherFields) {
        validateOtherField(tmdJSON.tmdReviews, othField, errorsArray, 'TMD Reviews', undefined, jsonKey);
    }
}

function validateMandatoryField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (!parentObject.hasOwnProperty(field.key)) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 0, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (field.hasOwnProperty("enum") && field.enum.indexOf(parentObject[field.key]) < 0) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 1, field.enum, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (parentObject[field.key].length === 0) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 2, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (field.hasOwnProperty('pattern')) {
        let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
        if (!patternValid) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function validateConditionalField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (field.hasOwnProperty("parentKey") && parentObject.hasOwnProperty(field.parentKey) && field.parentValue.indexOf(parentObject[field.parentKey]) > -1) {
        if (!parentObject.hasOwnProperty(field.key)) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 0, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        } else if (parentObject[field.key].length === 0) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 2, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        }
    } else if (field.hasOwnProperty("oneof")) {
        if (parentObject.hasOwnProperty(field.key) && field.hasOwnProperty("pattern")) {
            let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
            if (!patternValid) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
            } else {
                return; // One value is valid. No need to check other oneof values along with current value
            }
        }
        // Check if atleast one of oneof fields is not empty
        let parentKeys = Object.keys(parentObject);
        let atleastOneNonEmpty = false;
        field.oneof.map((key: string) => {
            if (parentKeys.indexOf(key) > -1 && parentObject[key] !== "") atleastOneNonEmpty = true;
        })
        if (!atleastOneNonEmpty) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 5, field.oneof, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function checkForCustomTags(productJSON: any, tags: ICustomTag[], errorsArray: IConsoleMessage[], sectionName: string, sectionId: string, nestingLevel: any = []): void {
    let data = productJSON;
    const section = sectionName;

    for (let tag of tags) {
        if (tag.mandatory && (!data || Object.keys(data).length === 0)) {
            errorsArray.push(createErrorMessage(tag.label, tag.key, 0, [], "", nestingLevel, section));
            continue;
        }
        
        // Then check for missing specific key if data exists
        if (tag.mandatory && data && !data[tag.key]) {
            errorsArray.push(createErrorMessage(tag.label, tag.key, 0, [], "", nestingLevel, section));
            continue;
        }

        let patternValid;
        let correctExample;
        switch (tag.type) {
            case 'IDENTIFIER':
                patternValid = isPatternValid(data[tag.key], "tagIdentifier").patternValid;
                correctExample = isPatternValid(data[tag.key], "tagIdentifier").correctExample
                if (!patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'DATE':
                patternValid = isPatternValid(data[tag.key], "date").patternValid;
                correctExample = isPatternValid(data[tag.key], "date").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'DATETIME':
                patternValid = isPatternValid(data[tag.key], "dateTime").patternValid;
                correctExample = isPatternValid(data[tag.key], "dateTime").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'BOOLEAN':
                patternValid = isPatternValid(data[tag.key], "boolean").patternValid;
                correctExample = isPatternValid(data[tag.key], "boolean").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'URL':
                patternValid = isPatternValid(data[tag.key], "uri").patternValid;
                correctExample = isPatternValid(data[tag.key], "uri").correctExample
                if (!patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'GROUP':
                if (tag && tag.tags && tag.tags.length > 0 && data[tag.key]) {
                    if (data[tag.key]) {
                        for (let group of data[tag.key]) {
                            for (let subTag of tag.tags) {
                                if (subTag.mandatory && !group[subTag.key]) {
                                    errorsArray.push(createErrorMessage(subTag.label, subTag.key, 0, [], "", nestingLevel, section));
                                    continue;
                                }
                                if (subTag.type === 'DATE') {
                                    patternValid = isPatternValid(group[subTag.key], "date").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "date").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'DATETIME') {
                                    patternValid = isPatternValid(group[subTag.key], "dateTime").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "dateTime").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'IDENTIFIER') {
                                    patternValid = isPatternValid(group[subTag.key], "tagIdentifier").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "tagIdentifier").correctExample
                                    if (!patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'BOOLEAN') {
                                    patternValid = isPatternValid(group[subTag.key], "boolean").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "boolean").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'URL') {
                                    patternValid = isPatternValid(group[subTag.key], "uri").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "uri").correctExample
                                    if (!patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                }
                            }
                        }
                    }
                }
            break;
        }
    }
}

function validateOtherField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (parentObject.hasOwnProperty(field.key)) {
        if (field.hasOwnProperty("pattern")) {
            let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
            if (!patternValid) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
            }
        }
        if (field.hasOwnProperty("enum") && field.enum.indexOf(parentObject[field.key]) < 0) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 1, field.enum, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function isPatternValid(value: any, patternType: string) {
    let patternValid;
    let correctExample = "";
    let pattern;
    switch (patternType) {
        case "uri":
            patternValid = isUrlValid(value);
            correctExample = "https://www.productcloud.com.au";
            break;
        case "dateTime":
            patternValid = moment(value, moment.ISO_8601).isValid();
            correctExample = "2007-05-01T15:43:00.12345Z";
            break;
        case "date":
            patternValid = moment(value, "YYYY-MM-DD", true).isValid();
            correctExample = "2007-05-01";
            break;
        case "tagIdentifier":
            pattern = /^[a-zA-Z0-9_-]+$/;
            patternValid = pattern.test(value as string);
            correctExample = "A1B-CD-E23";
            break;
        case "rate":
            pattern = /^[-]?\d{1,16}[.]?\d{0,16}$/;
            patternValid = pattern.test(value as string);
            correctExample = "12.345";
            break;
        case "iso8601D":
            pattern = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
            patternValid = pattern.test(value as string);
            correctExample = "P1DT1H";
            break;
        case "number":
            correctExample = "1.23";
            patternValid = false;
            if (typeof (value) === "number") {
                patternValid = true;
            } else if (typeof (value) === "string" || value instanceof String) {
                if (!isNaN(parseFloat(value as string))) {
                    patternValid = true;
                }
            }
            break;
        case "currency":
            pattern = /^...$/;
            patternValid = pattern.test(value as string);
            correctExample = "AUD";
            break;
        case "amount":
            pattern = /^[-]?\d{1,16}[.]\d{2,16}$/;
            patternValid = pattern.test(value as string);
            correctExample = "12.34";
            break;
        case "boolean":
            patternValid = typeof (value) === "boolean";
            correctExample = "true";
            break;
        case "stringArray":
            patternValid = Array.isArray(value);
            correctExample = "[ 'string' ]";
            break;
    }
    return {
        patternValid,
        correctExample
    }
}

function isUrlValid(urlString: string): boolean {
    if (!urlString || urlString === "") return true;
    try {
        if (!(/^[^\s]+$/.test(urlString))) return false;
        const url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return true;
}

function createErrorMessage(humanFormat: string, key: string, category: number, allowedValues: string[] = [], correctExample: string = "", nestingLevel: any = [], section?: string, ind?: number, jsonKey?: string): IConsoleMessage {
    /*
        Category Definitions for Error Messages:
        0: Missing mandatory field
        1: Value not in allowed values
        2: Cannot be empty
        3: Malformed Value
        4: Invalid Tag
        5: Atleast One
    */
    let errorMsg: string = "";
    let errorCategory: string = "";
    let jsonRef: string | undefined;
    switch (category) {
        case 0:
            errorCategory = "Missing Value";
            errorMsg = `Missing mandatory value: "${humanFormat}"`
            jsonRef = `JSON Key: "${key}"${(ind && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 1:
            errorCategory = "Disallowed Value";
            errorMsg = `Value of "${humanFormat}" not allowed. Allowed values: ${allowedValues}.`;
            jsonRef = `JSON Key: "${key}"${(ind && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 2:
            errorCategory = "Empty";
            errorMsg = `Key: "${humanFormat}" cannot be empty.`;
            jsonRef = `JSON Key: "${key}"${(ind && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 3:
            errorCategory = "Malformed Value";
            errorMsg = `Value of "${humanFormat}" is malformed. Example of correct value - ${correctExample}.`;
            jsonRef = `JSON Key: "${key}"${(ind && jsonKey)
                ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey
                : ` ${jsonKey ? 'in element - "' + jsonKey + '"' : ''}`
            }.`;
            break;
        case 4:
            errorCategory = "Invalid Tag";
            errorMsg = `Value of tag "${key}" is invalid. Example of correct value - ${correctExample}.`;
            break;
        case 5:
            errorCategory = "Atleast One";
            errorMsg = `Key "${key}" is missing${(ind && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}. Atleast one of ${allowedValues} is mandatory.`;
            break;
        default:
            errorCategory = "";
            errorMsg = "";
    }

    if (nestingLevel.length > 0) {
        errorMsg += ` Element present in `;
        nestingLevel.map((level: any) => {
            errorMsg += `${level.key} (Index: ${level.ind}) -> `
        })
        errorMsg = errorMsg.slice(0, errorMsg.length - 4)
    }

    const errorBody = {
        icon: "exclamation-triangle",
        source: "Schema Error",
        type: "Error",
        category: errorCategory,
        message: errorMsg,
        jsonKey: jsonRef,
        section: section
    }

    return errorBody;
}
